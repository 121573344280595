#chat-item {
  .item-detail {
    &_message {
      background: #fff;
      border: 1px solid #dcdcdc;

      &-file {
        max-width: 310px;
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
      }

      &_title {
        display: flex;
        width: 100%;
        padding: 26px 16px 0 16px;
        padding-bottom: 4px;
        align-items: start;
        justify-content: space-between;
        gap: 24px;
        border-bottom: 1px solid #dcdcdc;

        .ant-spin-nested-loading {
          width: 100%;

          .ant-tabs-nav-wrap {
            width: 100%;

            .ant-tabs-nav-list {
              width: 100%;
            }
          }
        }

        .custom-select__container {
          input {
            font-size: 12px !important;
            height: 28px;
          }
        }

        h6 {
          width: 192px;
          height: 40px;
          color: #7e7e7e;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          padding-bottom: 10px;
          padding-left: 8px;
          padding-top: 4px;
          border-bottom: 2px solid #7e7e7e;
        }

        button {
          display: flex;
          width: 24px;
          height: 24px;
          justify-content: center;
          align-items: center;
          background: transparent;
          border: none;
        }
      }

      &_status {
        background: #eef0f4;
        padding: 8px 16px;
        padding-left: 8px;
      }

      &_content {
        padding: 12px 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 320px;
        overflow: auto;
        border-bottom: 1.5px solid #adadad;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background: #adadad;
          border-radius: 12px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #dcdcdc;
        }

        &_other {
          display: flex;
          align-items: end;
          gap: 4px;

          &_avatar {
            img {
              width: 32px;
              height: 32px;
              object-fit: cover;
            }
          }

          &_text {
            &_content {
              color: #514b4d;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
              // display: flex;
              padding: 8px 16px;
              align-items: flex-start;
              gap: 10px;
              border-radius: 8px;
              background: #e9f3fe;
              // max-width: 260px;
              white-space: pre-wrap;
              word-wrap: break-word;
              width: fit-content;
              max-width: 70%;
            }

            .display-none {
              display: none;
            }

            &_translate {
              margin-top: 2px !important;
              margin-bottom: 2px !important;

              &_button {
                background: #ffffff;
                padding: 0;
                height: 16px;
                display: flex;
                align-items: baseline;

                span {
                  color: #7e7e7e;
                  font-size: 10px;
                  font-weight: 500;
                  margin-left: 4px;
                }
              }

              &_spin {
                width: 28px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }

            &_name {
              color: #514b4d;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 18px;
              /* 150% */
              text-align: left;
            }

            &_time {
              color: #7e7e7e;
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
            }
          }
        }

        &_me {
          display: flex;
          flex-direction: row-reverse;
          align-items: end;
          gap: 4px;
          padding: 10px;

          &_avatar {
            img {
              width: 32px;
              height: 32px;
              object-fit: cover;
            }
          }

          &_text {
            // display: flex;
            // align-items: end;
            // justify-content: end;
            &_content {
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
              /* 157.143% */
              // display: flex;
              padding: 8px 16px;
              align-items: flex-start;
              gap: 10px;
              border-radius: 8px;
              background: #1c86ed;
              white-space: pre-wrap;
              word-wrap: break-word;
              width: fit-content;
              max-width: 70%;
            }

            &_content-other {
              color: #514b4d;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
              /* 157.143% */
              // display: flex;
              padding: 8px 16px;
              align-items: flex-start;
              gap: 10px;
              border-radius: 8px;
              background: #e9f3fe;
              white-space: pre-wrap;
              word-wrap: break-word;
              width: fit-content;
              max-width: 70%;
            }

            &_name {
              color: #514b4d;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 18px;
              /* 150% */
              text-align: right;
            }

            &_time {
              color: #7e7e7e;
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              /* 160% */
              text-align: right;
            }
          }
        }

        &_seen {
          position: relative;
          display: flex;
          justify-content: center;

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            z-index: 0;
            background: #dcdcdc;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }

          p {
            display: inline-flex;
            align-items: center;
            gap: 8px;
            position: relative;
            z-index: 1;
            padding: 0 10px;
            background: #fff;

            span {
              color: #514b4d;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 22px;
              /* 157.143% */
            }
          }
        }
      }

      &_input-box {
        padding: 24px;
        display: flex;
        align-items: center;

        textarea {
          padding: 0;
          border: unset;
          height: 112px;
          resize: none;
          outline: unset;
          background-color: #ffffff;
          box-shadow: unset;
        }

        &-uploads {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-top: 8px;

          &_wrap {
            position: relative;
            width: fit-content;
            line-height: 40px;
          }

          &_close {
            display: flex;
            height: 16px;
            width: 16px;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
          }
        }

        &_wrap {
          display: flex;
          align-items: center;
          gap: 24px;

          &_photo {
            cursor: pointer;
            position: relative;

            input {
              width: 24px;
              height: 24px;
              overflow: hidden;
              opacity: 0;
              z-index: 1;
              position: relative;
            }

            div {
              position: absolute;
              width: 24px;
              height: 24px;
              top: 0;
              left: 0;
              z-index: 0;
            }
          }

          &_send {
            width: 40px;
            height: 40px;
            border: none;
            border-radius: 47px;
            background: #1c86ed;
            padding: 10px 10px 6px 6px;
          }
        }
      }
    }
  }

  .ant-tabs-tab {
    display: block;
    max-width: 192px;
    min-width: unset !important;
    width: calc(100% / 2);
    // flex: 1;

    .ant-tabs-tab-btn {
      display: flex;
      align-items: center;
      padding: 0;
      max-width: 192px;
      width: 100%;
      margin-bottom: 5px;
    }
  }

  // .ant-tabs-tab-active {
  //   .tab-icon {
  //     display: none;
  //   }
  // }

  .unread-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    right: 0;
  }

  .ant-custom-tabs .ant-tabs-tab {
    padding: 0;
  }
}

.ant-select-dropdown {
  padding: 0;

  .ant-select-item-option-selected {
    .assign-icon-checked {
      display: block !important;
    }
  }

  .ant-select-item {
    min-height: 28px !important;
  }
}

.ant-select-selector {
  .assign-icon-checked {
    display: none !important;
  }
}

.rc-virtual-list-scrollbar-thumb {
  display: none;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: unset;
}

.ant-select-item:hover {
  background-color: #f2f2f2 !important;
}

.image-preview__container {
  position: relative;

  .ant-image-preview-progress {
    display: none;
  }

  .ant-image-preview-switch-left,
  .ant-image-preview-switch-right {
    position: absolute;
    bottom: calc(100% + 18px);
    inset-block-start: unset !important;
    background-color: unset !important;
  }

  .ant-image-preview-operations-operation > .anticon {
    font-size: 16px !important;
  }

  .anticon {
    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }

  .ant-image-preview-switch-left {
    left: calc(50% - 120px);
  }

  .ant-image-preview-switch-right {
    left: calc(50% - 92px);
  }

  .ant-divider-vertical {
    margin-inline: unset !important;
  }

  .ant-image-preview-operations {
    padding: 0 16px;
    width: 256px;
    gap: 16px;
    height: 48px;
    justify-content: end;
  }

  .ant-image-preview-operations-operation {
    padding: 0;
    margin: 0;
  }
}

.image-preview__container.image-preview__container-limit {
  .ant-image-preview-operations {
    width: 200px !important;
    padding: 0 !important;
    justify-content: center !important;
  }
}

// .ant-image-preview-close {
//   right: unset !important;
//   top: unset !important;
//   left: calc(50% + 64px);
//   bottom: 49px;
//   background-color: unset !important;
//   padding: 0;
// }
